module.exports = {
  storeName: 'smplslaps Bodega',
  storeDescription: 'smplslaps - Custom thermal slaps, stickers,blanks and more',
  email: 'smpl@smplslaps.com',
  company: 'smplslaps Inc.',
  location: 'near you',
  address: 'Somewhere',
  phone: 'ヾ🤪ノ',
  workingDays: ' ',
  workingHours: ' ',
  socialNetworks: [
    /*'https://facebook.com/smplslaps/',*/
    'https://instagram.com/smplslaps',
    /*'https://pinterest.com',*/
    /*'https://twitter.com',*/
    /*'https://youtube.com',*/
  ],
  payments: ['visa', 'mastercard', 'amex', 'discover', 'shopify', 'paypal'],
  // For available socia share buttons see: https://github.com/nygardk/react-share
  shareButtons: [
    'Facebook',
    'Pinterest',
    'Twitter',
    'Tumblr',
    'Whatsapp',
    'Line',
    'Viber',
  ],
  googleAnalyticsId: 'UA-156453697-1',
  //
  // carousel, collection, product
  //
  mainPage: [
    {
      type: 'carousel',
      children: [
        {
          name: 'Keep it smpl slaps pack',
          type: 'product',
          handle: 'keep-it-smpl-slaps-pack',
          textColor: 'white',
          textBgColor: 'primary',
        },
        {
          name: 'Custom slaps',
          type: 'collection',
          handle: 'custom-slaps',
          textColor: 'white',
          textBgColor: 'primary',
        },
        {
          name: 'NYC Train - Blank slaps',
          type: 'product',
          handle: 'nyc-train-slaps-pack',
        },
      ],
    },
    {
      name: 'B&W Custom Slaps',
      type: 'product',
      handle: 'b-w-custom-slaps',
      textColor: 'white',
      textBgColor: 'primary',
    },
    {
      name: 'Custom Colored Slaps',
      type: 'collection',
      handle: 'custom-colored-slaps',
      textColor: 'white',
      textBgColor: 'primary',
    },
    {
      name: 'Smpl painted train model',
      type: 'product',
      handle: 'keep-it-smpl-painted-scale-train-model',
      textColor: 'white',
      textBgColor: 'primary',
    },
    {
      name: 'Slap packs',
      type: 'collection',
      handle: 'slap-packs',
      textColor: 'white',
      textBgColor: 'primary',
    },
    {
      name: 'Blank slaps',
      type: 'collection',
      handle: 'blank-slaps',
    },
    {
      name: 'NYC Train - Blank slaps',
      type: 'product',
      handle: 'nyc-train-slaps-pack',
      textColor: 'white',
      textBgColor: 'primary',
    },
    {
      name: 'Box truck - Blank slaps',
      type: 'product',
      handle: 'box-truck-blank-slaps-pack',
      textColor: 'white',
      textBgColor: 'primary',
    },
    {
      name: 'Do Not Remove - Blank slaps',
      type: 'product',
      handle: 'do-not-remove-blank-slaps-pack',
    },
  ],
  // Menu types: "header", "collection", "product", "link"
  menu: {
    name: 'Menu',
    type: 'top',
    children: [
      {
        name: "Custom slaps",
        type: 'header',
        handle: '',
        link: '',
        children: [
          {
            name: 'All custom slaps',
            type: 'collection',
            handle: 'custom-slaps',
          },
          {
            name: 'B&W Custom Slaps',
            type: 'product',
            handle: 'b-w-custom-slaps',
          },
          {
            name: 'Colored Custom Slaps',
            type: 'collection',
            handle: 'custom-colored-slaps',
          },
        ],
      },
      {
        name: "Blank slaps",
        type: 'header',
        children: [
          {
            name: 'All blanks',
            type: 'collection',
            handle: 'blank-slaps',
          },
          {
            name: 'Train blanks',
            type: 'product',
            handle: 'nyc-train-slaps-pack',
          },
          {
            name: 'Do Not Remove blanks',
            type: 'product',
            handle: 'do-not-remove-blank-slaps-pack',
          },
          {
            name: 'Box truck blanks',
            type: 'product',
            handle: 'box-truck-blank-slaps-pack',
          },
          /*{
            name: 'External links',
            type: 'header',
            children: [
              {
                name: 'External link 2',
                type: 'external',
                link: 'https://amazon.com',
              },
              {
                name: 'External link 3',
                type: 'external',
                link: 'https://amazon.com',
              },
              {
                name: 'External link 2',
                type: 'external',
                link: 'https://amazon.com',
              },
              {
                name: 'External link 3',
                type: 'external',
                link: 'https://amazon.com',
              },
              {
                name: 'External link 2',
                type: 'external',
                link: 'https://amazon.com',
              },
              {
                name: 'External link 3',
                type: 'external',
                link: 'https://amazon.com',
              },
              {
                name: 'External link 2',
                type: 'external',
                link: 'https://amazon.com',
              },
              {
                name: 'External link 3',
                type: 'external',
                link: 'https://amazon.com',
              },
              {
                name: 'External link 2',
                type: 'external',
                link: 'https://amazon.com',
              },
              {
                name: 'External link 3',
                type: 'external',
                link: 'https://amazon.com',
              },
              {
                name: 'External link 2',
                type: 'external',
                link: 'https://amazon.com',
              },
              {
                name: 'External link 3',
                type: 'external',
                link: 'https://amazon.com',
              },
              {
                name: 'External link 2',
                type: 'external',
                link: 'https://amazon.com',
              },
              {
                name: 'External link 3',
                type: 'external',
                link: 'https://amazon.com',
              },
              {
                name: 'External link 2',
                type: 'external',
                link: 'https://amazon.com',
              },
              {
                name: 'External link 3',
                type: 'external',
                link: 'https://amazon.com',
              },
              {
                name: 'External link 2',
                type: 'external',
                link: 'https://amazon.com',
              },
              {
                name: 'External link 3',
                type: 'external',
                link: 'https://amazon.com',
              },
              {
                name: 'External link 2',
                type: 'external',
                link: 'https://amazon.com',
              },
              {
                name: 'External link 3',
                type: 'external',
                link: 'https://amazon.com',
              },
              {
                name: 'External link 2',
                type: 'external',
                link: 'https://amazon.com',
              },
              {
                name: 'External link 3',
                type: 'external',
                link: 'https://amazon.com',
              },
              {
                name: 'External link 2',
                type: 'external',
                link: 'https://amazon.com',
              },
              {
                name: 'External link 3',
                type: 'external',
                link: 'https://amazon.com',
              },
              {
                name: 'External link 2',
                type: 'external',
                link: 'https://amazon.com',
              },
              {
                name: 'External link 3',
                type: 'external',
                link: 'https://amazon.com',
              },
            ],
          },*/
        ],
      },
      { name: 'Slap packs', type: 'collection', handle: 'slap-packs' },
      { name: 'Art & Collectibles', type: 'collection', handle: 'collectibles' },
    ],
  },
  footerLinks: [
    /*{
      name: 'About us',
      link: '/pages/about',
    },*/
    {
      name: 'Terms of Service',
      link: '/policy/termsOfService',
    },
    {
      name: 'Privacy policy',
      link: '/policy/privacyPolicy',
    },
    {
      name: 'Refunds',
      link: '/policy/refundPolicy',
    },
    /*{
      name: 'External',
      link: 'https://amazon.com',
    }, */
  ],
  locales: 'en-US',
  currency: 'USD',
  productsPerCollectionPage: '9',
  articlesPerBlogPage: '6',
};
import React, { Component } from "react";
import { Modal, ModalBackground, ModalContent } from "./Modal";
import EmailListForm from './EmailListForm';

class ExitIntentModal extends Component {
  constructor(props) {
    super(props);
    this.state = { showExit: false };
    // This binding is necessary to make `this` work in the callback
    this.handleExit = this.handleExit.bind(this);
    this.handleExitClick = this.handleExitClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mouseout", this.handleExit);
    let visited = sessionStorage["alreadyVisited"];
        if(visited) {
             this.setState({ viewPopup: false })
             //do not view Popup
        } else {
             //this is the first time
             sessionStorage["alreadyVisited"] = true;
             this.setState({ viewPopup: true});
        }
        if(this.state.showExit){
          document.body.style.overflow = 'hidden';
        }
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset';
}

  handleExitClick(e) {
    this.setState({
      showExit: false,
    });
  }

  handleExit(e) {
    if(this.state.viewPopup) {
        e = e ? e : window.event;
        // Get the current viewport width.
        var vpWidth = Math.max(
        document.documentElement.clientWidth,
        window.innerWidth || 0
        );

        // If the current mouse X position is within 50px of the right edge
        // of the viewport, return.
        if (e.clientX >= vpWidth - 50) return;

        // If the current mouse Y position is not within 50px of the top
        // edge of the viewport, return.
        if (e.clientY >= 50) return;

        // Reliable, works on mouse exiting window and
        // user switching active program
        var from = e.relatedTarget || e.toElement;
        if (!from) {
        this.setState({
            showExit: true,
            viewPopup: false,
        });
        } 
    }
  }

  render() {
    const { showExit } = this.state;

    return (
      <Modal showExit={showExit}>
        <ModalBackground onClick={this.handleExitClick} />
        <ModalContent>
          <SprayCanIcon />
          <EmailListForm />
        </ModalContent>
      </Modal>
    );
  }
}

export default ExitIntentModal;

const SprayCanIcon = () => (
  <svg
    width="100%"
    height="100"
    viewBox="0 0 90 70"
    fill="#000000"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g><path d="M16,42.2h-0.5l-1.7-2.5c-0.1-0.2-0.4-0.3-0.6-0.3h-1.4v-0.7c0.4,0,0.7-0.3,0.7-0.7v-2.1c0-0.4-0.3-0.7-0.7-0.7h-1.4   c-0.4,0-0.7,0.3-0.7,0.7V38c0,0.4,0.3,0.7,0.7,0.7v0.7H9c-0.2,0-0.5,0.1-0.6,0.3l-1.7,2.5H6.1c-0.8,0-1.4,0.6-1.4,1.4v23.9   c0,0.8,0.6,1.4,1.4,1.4H16c0.8,0,1.4-0.6,1.4-1.4V43.6C17.4,42.8,16.8,42.2,16,42.2z M9.4,40.8h1.7c0,0,0,0,0,0s0,0,0,0h1.8   l0.9,1.4H8.4L9.4,40.8z"/><path d="M48.3,42.2h-0.5l-1.7-2.5c-0.1-0.2-0.4-0.3-0.6-0.3h-1.4v-0.7h0.6c0.4,0,0.7-0.3,0.7-0.7v-2.1c0-0.4-0.3-0.7-0.7-0.7h-2.5   c-0.4,0-0.7,0.3-0.7,0.7V38c0,0.4,0.3,0.7,0.7,0.7h0.6v0.7h-1.4c-0.2,0-0.5,0.1-0.6,0.3l-1.7,2.5h-0.6c-0.8,0-1.4,0.6-1.4,1.4v23.9   c0,0.8,0.6,1.4,1.4,1.4h9.8c0.8,0,1.4-0.6,1.4-1.4V43.6C49.7,42.8,49.1,42.2,48.3,42.2z M42.8,36.6H44v0.7h-1.1V36.6z M39.9,45h7   v5.6h-7V45z M41.7,40.8h1.7c0,0,0,0,0,0s0,0,0,0h1.8l0.9,1.4h-5.3L41.7,40.8z M39.9,66.1v-5.6h7v5.6H39.9z"/><path d="M34.3,28.1h-0.5l-1.7-2.5c-0.1-0.2-0.4-0.3-0.6-0.3H30v-0.7c0.4,0,0.7-0.3,0.7-0.7v-2.1c0-0.4-0.3-0.7-0.7-0.7h-1.4   c-0.4,0-0.7,0.3-0.7,0.7v2.1c0,0.4,0.3,0.7,0.7,0.7v0.7h-1.4c-0.2,0-0.5,0.1-0.6,0.3L25,28.1h-0.6c-0.8,0-1.4,0.6-1.4,1.4v38   c0,0.8,0.6,1.4,1.4,1.4h9.8c0.8,0,1.4-0.6,1.4-1.4v-38C35.7,28.8,35,28.1,34.3,28.1z M32.9,45.7l-7,4v-2.6l7-4V45.7z M32.9,41.5   l-7,4v-7.1l7-4V41.5z M25.8,51.3l7-4v2.6l-7,4V51.3z M32.9,30.9v1.8l-7,4v-5.8H32.9z M25.8,55.6l7-4v2.6l-7,4V55.6z M25.8,59.8l7-4   v2.6l-7,4V59.8z M27.6,26.7h1.7c0,0,0,0,0,0s0,0,0,0h1.8l0.9,1.4h-5.3L27.6,26.7z M25.8,66.1V64l7-4v6.1H25.8z"/><path d="M84.6,61.1h-2.1c-0.4,0-0.7,0.3-0.7,0.7h-0.7v-1.4c0-0.2-0.1-0.5-0.3-0.6l-2.5-1.7v-0.6c0-0.8-0.6-1.4-1.4-1.4H52.9   c-0.8,0-1.4,0.6-1.4,1.4v9.8c0,0.8,0.6,1.4,1.4,1.4h23.9c0.8,0,1.4-0.6,1.4-1.4v-0.5l2.5-1.7c0.2-0.1,0.3-0.4,0.3-0.6v-1.4h0.7   c0,0.4,0.3,0.7,0.7,0.7h2.1c0.4,0,0.7-0.3,0.7-0.7v-1.4C85.3,61.4,85,61.1,84.6,61.1z M54.3,59h21.1v7H54.3V59z M79.6,64.3   l-1.4,0.9v-5.3l1.4,0.9V64.3z"/></g>
  </svg>
);
// import styled, { keyframes } from "styled-components";
import styled from "@emotion/styled";

// const bounceIn = keyframes`
//   0% {
//     -webkit-transform: translateY(-500px);
//             transform: translateY(-500px);
//     -webkit-animation-timing-function: ease-in;
//             animation-timing-function: ease-in;
//     opacity: 0;
//   }
//   38% {
//     -webkit-transform: translateY(0);
//             transform: translateY(0);
//     -webkit-animation-timing-function: ease-out;
//             animation-timing-function: ease-out;
//     opacity: 1;
//   }
//   55% {
//     -webkit-transform: translateY(-65px);
//             transform: translateY(-65px);
//     -webkit-animation-timing-function: ease-in;
//             animation-timing-function: ease-in;
//   }
//   72% {
//     -webkit-transform: translateY(0);
//             transform: translateY(0);
//     -webkit-animation-timing-function: ease-out;
//             animation-timing-function: ease-out;
//   }
//   81% {
//     -webkit-transform: translateY(-28px);
//             transform: translateY(-28px);
//     -webkit-animation-timing-function: ease-in;
//   }
//   90% {
//     -webkit-transform: translateY(0);
//             transform: translateY(0);
//     -webkit-animation-timing-function: ease-out;
//             animation-timing-function: ease-out;
//   }
//   95% {
//     -webkit-transform: translateY(-8px);
//             transform: translateY(-8px);
//     -webkit-animation-timing-function: ease-in;
//             animation-timing-function: ease-in;
//   }
//   100% {
//     -webkit-transform: translateY(0);
//             transform: translateY(0);
//     -webkit-animation-timing-function: ease-out;
//             animation-timing-function: ease-out;
//   }
// `;

export const Modal = styled.div`
  display: ${props => (props.showExit ? "flex" : "none")};
  color: #212529;
  font-weight: bold;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  z-index: 1031;
`;

export const ModalBackground = styled.div`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  background-color: rgba(10, 10, 10, 0.86);
`;

export const ModalContent = styled.div`
  margin: 0 20px;
  max-height: calc(100vh - 160px);
  overflow: auto;
  position: relative;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 5px;
`;
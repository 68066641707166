import React, { useState } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
 import { Heading, Box, Flex } from 'rebass';
import styled from "@emotion/styled";
// import * as styles from './EmailListForm.module.scss';

const EmailListForm = props => {

    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [subscribed, setSubscribed] = useState(false);
  
    function handleSubmit(event) {
      event.preventDefault();
  
      addToMailchimp(email)
        .then(data => {
          if (data.result === "error") {
            throw data;
          }
  
          setSubscribed(true);
          setEmail("");
  
          setTimeout(() => {
            setSubscribed(false);
          }, 6000);
        })
        .catch(error => {
          setError(error.msg);
        });
    }
  
    function handleEmailChange(event) {
      setEmail(event.currentTarget.value);
      setError("");
    }
  
    return (
    <Flex flexWrap="wrap" px={2} pt={3} mx="auto" style={{ maxWidth: 1300}}>
      <Box width={1} p={1}>
        <SubscriptionContainer>
          <Content>
            <Heading style={{ marginBottom: 8}}>
              Our list closes its doors once it hits 500 subs!
            </Heading>
            <Text>
              Subscribe now its filling up fast! We dont send out email very often but when we do, you will be among the first to know when our next rare drops and discounts will happen. Opt-out at anytime to free up a spot for someone else.
            </Text>
            <Form onSubmit={handleSubmit} hasError={error}>
              <Input
                placeholder="your@email.com"
                name="email"
                type="email"
                value={email}
                onChange={handleEmailChange}
                hasError={error}
              />
              <Button
                type="submit"
                hasError={error}
                subscribed={subscribed}
                disabled={subscribed}
              >
                {subscribed ? <CheckMarkIcon /> : "Subscribe"}
              </Button>
              {error && <Error dangerouslySetInnerHTML={{ __html: error }} />}
            </Form>
          </Content>
        </SubscriptionContainer>
      </Box>
    </Flex>
    );
  };

export default EmailListForm;

const SubscriptionContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 55px 0 55px;
  margin: 10px auto 0px;
  background: #f5f5f5;
  // box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
  z-index: 1;
`;

const Content = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 640px;
`;

const Text = styled.p`
  margin: 0 auto 30px;
  color: #757575;
  line-height: 1.75;
`;

const Form = styled.form`
  position: relative;
  max-width: 471px;
  &::after {
    content: ">";
    position: absolute;
    left: 21px;
    top: 13px;
    color: #43A047
  }
`;

const Input = styled.input`
  position: relative;
  background: ${p =>
    p.hasError
      ? p.theme.colors.errorBackground
      : p.theme.colors.inputBackground};
  border-radius: 35px;
  border: none;
  padding: 13px 21px 13px 35px;
//   max-width: 471px;
  width: calc(100% - 36px);
  color: ${p => p.theme.colors.primary};
  ::placeholder {
    color: #C5C5C5;
    opacity: 1;
  }
  :-ms-input-placeholder {
    color: #C5C5C5;
  }
  ::-ms-input-placeholder {
    color: #C5C5C5;
  }
`;

const Button = styled.button`
  position: absolute;
  left: calc(100% - 181px);
  top: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 141px;
  height: 44px;
  border: 2px solid
    ${p => (p.hasError ? p.theme.colors.error : p.theme.colors.accent)};
  color: ${p => (p.hasError ? p.theme.colors.error : p.theme.colors.accent)};
  background: ${p => (p.subscribed ? p.theme.colors.accent : "transparent")};
  font-weight: 600;
  border-radius: 35px;
  letter-spacing: 0.42px;
  transition: border-color 0.2s var(--ease-in-out-quad),
    background 0.2s var(--ease-in-out-quad), color 0.2s var(--ease-in-out-quad);
  &:hover {
    background: ${p =>
      p.hasError ? p.theme.colors.error : p.theme.colors.accent};
    color: #43A047;
  }
  &[disabled] {
    cursor: not-allowed;
  }
  svg * {
    fill: #43A047;
  }
`;

const Error = styled.div`
  position: absolute;
  left: 35px;
  bottom: -20px;
  color: #e53935};
  font-size: 12px;
  a {
    color: #e53935;
    text-decoration: underline;
  }
`;

const CheckMarkIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.00016 16.1698L4.83016 11.9998L3.41016 13.4098L9.00016 18.9998L21.0002 6.99984L19.5902 5.58984L9.00016 16.1698Z"
      fill="#08080B"
    />
  </svg>
);
